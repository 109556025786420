<template>
    <van-config-provider theme="dark">
        <router-view></router-view>
        <van-tabbar v-model="active" fixed safe-area-inset-bottom route active-color="var(--van-text-color)"
            placeholder>
            <van-tabbar-item v-for="t in tabbars" :to="{ name: t.name }" :name="t.name"
                :icon="active === t.name ? t.activeIcon : t.icon">{{ t.text }}</van-tabbar-item>
        </van-tabbar>
    </van-config-provider>
</template>
<script>
export default {
    name: 'App',
    data() {
        return {
            active: 'Home',
            tabbars: [
                { name: 'Home', icon: 'play-circle-o', activeIcon: 'play-circle', text: '首页' },
                { name: 'Genre', icon: 'like-o', activeIcon: 'like', text: '分类' },
                { name: 'Me', icon: 'user-circle-o', activeIcon: 'manager', text: '我' }
            ],
        }
    }
}
</script>
<style>
html,
body {
    height: 100%;
    background-color: var(--van-background);
}

#app {
    height: 100%;
    max-width: 1200px;
    margin: auto;
}

:root:root {
    --van-nav-bar-height: 54px;
    --van-nav-bar-icon-color: var(--van-text-color);
}
</style>
