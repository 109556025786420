export const routes = [
    {
        name: 'Home',
        path: '/',
        component: () => import('./components/Home.vue'),
    }, {
        name: 'Genre',
        path: '/genre',
        component: () => import('./components/Genre.vue'),
    }, {
        name: 'Me',
        path: '/me',
        component: () => import('./components/Me.vue'),
    }, {
        name: 'Movie',
        path: '/movie/:id',
        component: () => import('./components/Movie.vue'),
    }
];